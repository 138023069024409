.container {
	position:relative;
	display:flex;
	flex:1;
	flex-direction:column;
	width:100%;
	height:100vh;
	background:#fff;
	overflow:hidden;
}

.container header {
	display:flex;
	align-items:center;
	justify-content:flex-start;
	width:100%;
	height:80px;
	padding:0 20px;
	box-shadow: 0 2px 4px rgb(0 0 0 / 10%);
}
.container .license-error,
.container .checklist-error {
	text-align:center;
	padding:10px 15px;
	color:#fff;
	background:#f44336;
}
.container .license-error a,
.container .checklist-error a {
	font-weight:bold;
	color:#fff;
	text-decoration:none;
	border-bottom:1px solid #fff;
}
.container .checklist-error {
	background:#2196f3;
}
.container header ul.main-menu {
	display:flex;
	flex-direction:row;
	align-items:center;
	width:100%;
	list-style:none;
}
.container header ul.main-menu li {
	position:relative;
	margin-right:5px;
	padding:8px 10px 8px 8px;
	border-radius:12px;
	cursor:pointer;
}
.container header ul.main-menu li.active {
	background:#eee;
}
.container header ul.main-menu li:hover {
	background:#f4f6f7;
}
.container header ul.main-menu li .menu-item,
.container header ul.main-menu li ul li a {
	display:flex;
	flex-direction:row;
	align-items:center;
	font-weight:bold;
	color:#000;
	text-decoration:none;
}
.container header ul.main-menu li.dayshift {
	margin-left:auto;
	background:#4caf50;
}
.container header ul.main-menu li.dayshift.dayshift-warning {
	background:#f44336;
}
.container header ul.main-menu li.dayshift div {
	color:#fff;
}
.container header ul.main-menu li:last-child {
	margin-right:0;
}
.container header ul.main-menu li .menu-item img {
	margin-right:5px;
}
.container header ul.main-menu li.more {
	padding-right:5px;
}
.container header ul.main-menu li.more .menu-item img.arrow-down {
	margin:0;
}
.container header ul.main-menu li.select {
	background:#eee;
}
.container header ul.main-menu li.select.select-yellow {
	background:#ffc060;
}
.container header ul.main-menu li.more:hover .submenu {
	display:block;
}
.container header ul.main-menu li.more ul {
	width:200px;
	padding:8px 0;
	background:#fff;
	border-radius:12px;
	box-shadow:0 4px 16px 0 #3d3d3d29;
}
.container header ul.main-menu li.more ul li {
	position:unset;
	margin:0;
	padding:8px 0 8px 15px;
	border-radius:0;
	cursor:pointer;
}
.container header ul.main-menu li.more ul li a {
	display:block;
}
.container header ul.main-menu li.more ul li.point {
	background: #fafafa;
}
.container header ul.main-menu li.more ul li.point:hover {
	background:#f4f6f7;
}
.container header ul.main-menu li.more ul li.point.point-warning,
.container header ul.main-menu li.more ul li.point.point-warning:hover {
	background:#f4433620;
}
.container header ul.main-menu li.more ul li span {
	display:block;
	font-size:90%;
	color:#4a4d4f;
	font-weight:normal;
}
.container header ul.main-menu li.more ul li span.dayshiftnumber {
	font-size:100%;
	color:#777;
}
.container header ul.main-menu li.more ul li.point.point-warning span.dayshiftnumber {
	color:#f44336;
}
.container header ul.main-menu li.more .submenu {
	display:none;
	position:absolute;
	top:40px;
	left:0;
	padding:6px 0 0;
	z-index:1000;
}
.container header ul.main-menu li.more .submenu.user-menu {
	left:unset;
	right:0;
}

.container main {
	height:calc(100% - 80px);
	overflow:hidden;
}

.modal .points {
	margin:10px 0 30px;
}
.modal .points .point {
	height:44px;
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
	margin-bottom:2px;
	padding:10px;
	color:#4a4d4f;
	background:#fafafa;
	text-decoration:none;
}
.modal .points .point:hover {
	color:#000;
	border-radius:8px;
	background:#f2f2f3;
	cursor:pointer;
}
.modal .points .point.point-add {
	color:#000;
	background:transparent;
	margin-top:10px;
}
.modal .points .point.point-add:hover {
	background:#f2f2f3;
}
.modal.modal-dayshift {
	width:460px;
}
.modal.modal-dayshift .warning {
	color:#f44336 !important;
}
.modal.modal-dayshift p {
	color:#4a4d4f;
}
.modal.modal-dayshift .dayshifts {
	margin:20px 0;
}
.modal.modal-dayshift .dayshifts .dayshift {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
	margin-bottom:10px;
	padding:10px 15px 12px;
	border-radius:12px;
	border-bottom:1px solid #f2f2f3;
	background:#f8f8f8;
}
.modal.modal-dayshift .dayshifts .dayshift.dayshift-warning {
	background:#f4433620;
	color:#fff;
}
.modal.modal-dayshift .dayshifts .number {
	display:flex;
	flex-direction:row;
	align-items:center;
	margin:10px 0 2px;
	font-size:120%;
	color:#000;
	font-weight:bold;
}
.modal.modal-dayshift .dayshifts .area {
	color:#000;
	font-weight:bold;
}
.modal.modal-dayshift .dayshifts .date {
	font-size:90%;
	color:#4a4d4f;
}
.modal.modal-dayshift .dayshifts .number .status {
	display:inline-block;
	margin-left:5px;
	padding:2px 8px;
	border-radius:6px;
	font-size:80%;
	color:#fff;
	background:#4caf50;
	font-size:60%;
	font-weight:normal;
	text-transform:uppercase;
}
.modal.modal-dayshift .dayshifts .number .status.status-timeout {
	background:#f44336;
}
.modal.modal-dayshift .dayshifts .number .status.status-closed {
	background:#f2f2f3;
	color:#4a4d4f;
}
.modal.modal-dayshift .dayshifts span {
	font-size:90%;
	color:#4a4d4f;
}
.modal.modal-dayshift .dayshifts .dayshift button {
	padding:8px 12px;
	color:#fff;
	border:none;
	border-radius:8px;
	background:#000;
	cursor:pointer;
}

.modal.modal-about .buttons {
	margin-top:25px;
}
.modal.modal-about .version {
	margin-top:30px;
	margin-bottom:0;
	font-size:90%;
	color:#4a4d4f;
}
.modal.modal-about .buttons button {
	width:100%;
}
.modal.modal-about .history {
	font-size:90%;
	color:#999;
}
.modal.modal-about .history:hover {
	color:#4a4d4f;
	cursor:pointer;
}
.modal.modal-about .legal {
	margin-top:15px;
	line-height:1.5;
}
.modal.modal-about .legal a {
	margin-right:15px;
	display:inline-block;
	font-size:85%;
	color:#666;
}
.modal.modal-about .legal a:hover {
	color:#4a4d4f;
	cursor:pointer;
	text-decoration:none;
	cursor:pointer;
}

.modal.modal-version {
	width:580px;
	height:600px;
}
.modal.modal-version ul.tabs {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
	margin:20px 0;
	padding:5px;
	border-radius:12px;
	background:#f4f6f7;
}
.modal.modal-version ul.tabs li {
	padding:5px 15px;
	border-radius:8px;
	cursor:pointer;
}
.modal.modal-version ul.tabs li.select {
	background:#3d3d3d;
	color:#fff;
}
.modal.modal-version .version-content {
	margin-left:10px;
	height:calc(600px - 145px);
	overflow-y:auto;
	scrollbar-color:#bbb transparent;
	scrollbar-width:thin;
}